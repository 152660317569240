import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./AuthContext";

//ProtectedRoute directs user to login page
const ProtectedRoute = () => {
  const { user } = useContext(AuthContext);
  console.log({ user });
  //if isAuthenticated = False, we are redirected to login page
  if (!user) {
    return <Navigate to="index" />;
  }
  //if isAuthenticated = True, we see rendered content components
  return <Outlet />;
};

export default ProtectedRoute;