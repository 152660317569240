// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { auth as firebaseuiAuth } from 'firebaseui';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBBm_-9Jed3RI72Gs5n6Kzhj3HdiJQIoUs",
  authDomain: "wedding-2023-150a0.firebaseapp.com",
  projectId: "wedding-2023-150a0",
  storageBucket: "wedding-2023-150a0.appspot.com",
  messagingSenderId: "613202566799",
  appId: "1:613202566799:web:f83bf2ea7a0002e46f5afa",
  measurementId: "G-S95QS1HJP7"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
// export const ui = new firebaseuiAuth.AuthUI(auth);




