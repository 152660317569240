// UnauthenticatedPage.tsx

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LoginPage from './Login';
import SignUpPage from './SignUp';

function Index() {
  return (
    <>
    <Container fluid style={{display: 'flex', overflowY: 'scroll', backgroundColor:'rgb(40, 44, 52)', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100vw', height: '100vh'}}>
        <Row style={{height: '100%',  paddingBottom: '50px', paddingTop:'50px'}}>
            <Col sx={12} style={{paddingBottom: '30px', height: 'auto', overflowY: 'scroll'}}>
                <h1>Welcome</h1>
                <p> Please log in or sign up below to
                    view wedding schedule and details.</p>
                <h2>- log in - </h2>
                <LoginPage />
                <h2 style={{paddingTop: '30px'}}>- sign up -</h2>
                <SignUpPage />
            </Col>
        </Row>
    </Container>
    </>
  );
}

export default Index;
