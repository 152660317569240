import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './firebaseConfig';
import 'firebase/auth';
import * as firebase from 'firebase/app';
import { User } from 'firebase/auth';


type AuthContextType = {
  user: User | null;
  loading: boolean;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: false,
});


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authUser => {
      setUser(authUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider
  value={{ user, loading}}>
  {children}
</AuthContext.Provider>;
};
