import { Link } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";
import LogoutButton from "./LogoutButton";
import { auth } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faCircleQuestion, faHouse, faNewspaper, faPaperPlane, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const NewFooter: React.FC = () => {
    const { width } = useWindowSize();

    const isMobile = width ? width >= 768 : false; 

    const navigate = useNavigate();
    const logout = () => {
        auth.signOut()
            .then(() => {
                navigate('/login'); // Navigate to login page after successful logout
            })
            .catch((error) => {
                console.error("Error signing out: ", error);
            });
    }
  

    return <>

    <div className="new-footer">
                <>&copy; 2023 Mr & Mrs. Reynolds - All rights reserved.</>
    </div></>
};
export default NewFooter;