import React, { useState } from 'react';
import { auth } from './firebaseConfig';
import { createUserWithEmailAndPassword } from "firebase/auth";

const SignUpPage: React.FC = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null); // State for success message

  const isEmailValid = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const signUpWithEmailAndPassword = async (email: string, password: string) => {
    setError(null); // Reset any previous error
    setSuccess(null); // Reset any previous success message

    if (!isEmailValid(email)) {
        setError("Please enter a valid email address.");
        return;
    }

    if (password.length < 6) {
        setError("Password must be at least 6 characters long.");
        return;
    }

    if (password !== confirmPassword) {
        setError("Passwords do not match.");
        return;
    }

    try {
        await createUserWithEmailAndPassword(auth, email, password);
        setSuccess("Successfully signed up!"); // Set success message on successful registration
    } catch (error: any) {
        console.error("Error signing up: ", error);
        if (error && typeof error.message === 'string') {
            setError(error.message);
        } else {
            setError("An error occurred during sign up.");
        }
    }
};

  return (
    <div>
        <input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
        <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
        <input type="password" placeholder="Confirm Password" onChange={e => setConfirmPassword(e.target.value)} />
        <button onClick={() => signUpWithEmailAndPassword(email, password)}>Sign Up</button>
        {error && <p style={{color: 'red'}}>{error}</p>}
        {success && <p style={{color: 'green'}}>{success}</p>} {/* Display success message */}
    </div>
  );
}

export default SignUpPage;
