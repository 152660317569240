import { Col, Row, Container } from "react-bootstrap";

const FAQ: React.FC = () => {
    return <Container fluid style={{height: '100vh', textAlign: 'center', padding: '20px', width: '100vw', backgroundColor: 'rgb(40, 44, 52)', }}>
    <Row style={{paddingBottom: '70px'}}>
        <Col>
        <h1>FAQ </h1>
        </Col>
    </Row>
    <Row style={{overflowY: 'scroll', height: '50%'}}>
        <Col>
            <ul>
                <li>
                    Q: Can I bring guests that haven't RSVP-ed?
                </li>
                <li>
                    A: We would love to accomodate your plus one, however there is a limit on how many guests can attend due to venue restrictions. 
                </li>
                <li>
                    Q: Will there be an open bar?
                </li>
                <li>
                    A: Alcohol will be served with dinner on Saturday night, but we ask guests to kindly respect a maximum of 3 glasses of wine/beer until everyone has had a drink. 
                </li>
                <li>
                    Q: Can I BYOB?
                </li>
                <li>
                    A: Don't ask, we won't tell. Please be discrete and respectful of the lodge. 
                </li>
            </ul>
        </Col>
    </Row>
   

    </Container>
};
export default FAQ;