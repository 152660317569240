import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import MainApp from './components/MainApp'; // This will be our new component


function App() {
  return (
      <MainApp />
  );
}

export default App;
