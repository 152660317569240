import React, { useState } from 'react';
import { auth } from './firebaseConfig';
import { signInWithEmailAndPassword as signInWithEmail } from "firebase/auth";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const signInWithEmailAndPassword = async (email: string, password: string) => {
    setLoading(true); // Begin loading state
    setError(null); // Reset any previous error
    setSuccess(null); // Reset any previous success message

    try {
      await signInWithEmail(auth, email, password);
      setSuccess("Successfully signed in!"); // Set the success message
    } catch (error: any) {
      console.error("Error signing in: ", error);
      if (error && typeof error.message === 'string') {
        setError(error.message);
      } else {
        setError("An error occurred during sign in.");
      }
    } finally {
      setLoading(false); // End loading state regardless of outcome
    }
  };

  return (
    <div>
      <input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
      <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
      <button onClick={() => signInWithEmailAndPassword(email, password)} disabled={loading}>
        {loading ? "Signing In..." : "Sign In"}
      </button>
      {error && <p style={{color: 'red'}}>{error}</p>}
      {success && <p style={{color: 'green'}}>{success}</p>}
    </div>
  );
}

export default LoginPage;
