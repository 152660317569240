import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLottie } from "lottie-react";
import animationData from "./assets/constructionani.json";
import CountdownTimer from './Countdown';
import LoginPage from './Login';
import backgroundImg from './assets/main1.jpg'
import { ReactComponent as WeddingLogo } from './assets/weddinglogo.svg'

const centerDiv: React.CSSProperties = {
  position: 'relative',
  justifyContent: 'center',
  alignContent: 'center',
  width: '100%',
  height: '100vh',
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: 'cover', 
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  paddingBottom: '150px',
  overflowY: 'scroll',
};

  const constructionSVG = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings:{
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const overlayStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    zIndex: 1
  };

  const mobileHeader: React.CSSProperties = {
    textAlign: 'center', 
    backgroundColor: 'rgba(54, 60, 78, 0.6)', 
    paddingTop: '10px', 
    paddingLeft: '30px', 
    paddingBottom: '40px', 

  }

  const fsHeader: React.CSSProperties = {
    textAlign: 'left',
  }
  


const Home: React.FC = () => {
    const { View } = useLottie(constructionSVG)


    return <Container fluid style={centerDiv} >
            <div style={overlayStyle}></div>


    <Row noGutters>
      {/* <Col style={{textAlign: 'center'}} sm={3}>
        <WeddingLogo />
      </Col> */}
      <Col style={mobileHeader} sm={12}>
        
        <h1>Drew & </h1>
        <h1>Hunter</h1>
      </Col>

    </Row>
    <Row >
        <Col style={{position: 'relative', textAlign: 'center', backgroundColor: 'rgba(54, 60, 78, 0.6)', height: '100%', paddingTop: '20px', paddingBottom: '20px', marginTop: '20px'}}  sm={12}>
        <p className="i"> A toast to love, laughter, and happily ever after.</p>
        <p>You're invited to our wedding celebration!</p>
        <p>Click <a href="https://forms.gle/H2pmVThE3zaxoLh28">HERE</a> to RSVP!</p>  
        </Col>
    </Row>
    <Row>
      <Col style={{position: 'relative', textAlign: 'center', backgroundColor: 'rgba(54, 60, 78, 0.6)', height: '100%', paddingTop: '20px', paddingBottom: '20px', marginTop: '20px'}}  sm={12}>
        <h2>- Save the date -</h2>   
        <p>December 2nd, 2023</p> 
        <p><CountdownTimer /></p> 
      </Col>
    </Row>
    {/* <Row>
      <Col style={{textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.5)', height: '100%', paddingTop: '40px', paddingBottom: '40px', marginTop: '20px', marginBottom: '70px'}} sm={12}>
          <h2>Save the date!</h2>   
          <h2>December 2nd, 2023</h2> 
          <h3><CountdownTimer /></h3>        
      </Col>
    </Row> */}
  </Container>;
};
export default Home;