import React from 'react';
import { ReactComponent as WeddingLogo } from './assets/weddinglogo.svg'
import { useState, useEffect } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from 'react-bootstrap/Container';
import { Col,Row } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Sidebar from './SideBar';
import { useWindowSize } from 'react-use';
import {
    Link
  } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { auth } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';


function NewHeader() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  
  const { width } = useWindowSize();

  const isMobile = width ? width >= 768 : false; 

  const navigate = useNavigate();

  const logout = () => {
      auth.signOut()
          .then(() => {
              navigate('/login'); // Navigate to login page after successful logout
          })
          .catch((error) => {
              console.error("Error signing out: ", error);
          });
  }

  const logoSize: React.CSSProperties = { 
    height: '250px', 
    width: '250px', 
    color: '#641F32', 
    position: 'absolute', 
    top: '-10px', 
   left: '-20px'
  }
  const logoSizeSmall: React.CSSProperties = { 
    height: '200px', 
    width: '200px', 
    color: '#641F32', 
    position: 'absolute', 
    top: '-20px', 
    left: '-10' 
  }
  // {height: '125px', width: '25px', color: '#641F32'}

  const mainSize = {width: '50px', color: '#641F32'}
  const mainSizeSmall = {height: '125px', width: '50px', color: '#641F32'}

  return (
    <>
      <Navbar id="header">
        <Container>
          <div style={mainSize}>
            <Link to="/">
              {/* <img style={{position: 'absolute', left: '-10px', top: '-15px', }} src={WeddingLogo} height="90px" alt='D&H' /> */}
            </Link>
          </div>
          {isMobile ? (
          <div className="navigation-component" style={{ width: '100%', textAlign: 'right',}}>
            <Link id="link" to="/">Home</Link>
            {/* <Link id="link" to="/ourStory">Our Story</Link> */}
            {/* <Link id="link" to="/auth">Log In</Link> */}
            <Link id="link" to="/details">Details</Link>
            <Link id="link" to="/schedule">Schedule</Link>
            <Link id="link" to="/FAQ">FAQ</Link>
            <a id="link" href="https://forms.gle/H2pmVThE3zaxoLh28">RSVP</a>
            <LogoutButton onLogout={logout} />
          </div>
          ) : (
            <></>
          // <div style={{paddingRight: '10px',}}>
          //     <button className="hamburger-menu" onClick={toggleSidebar}><FontAwesomeIcon icon={faBars} /></button>
          //     <Sidebar isOpen={sidebarOpen} onClose={toggleSidebar} />
          // </div>
          )}
        </Container>
      </Navbar>
    </>
  );
}

export default NewHeader;