import { Container, Col, Row } from "react-bootstrap";

const Schedule: React.FC = () => {
    return <Container fluid style={{height: '100vh', textAlign: 'center', padding: '20px', width: '100vw', backgroundColor: 'rgb(40, 44, 52)',}}>
    <Row>
        <Col>
        <h1>Schedule</h1>
        </Col>
    </Row>
    <Row style={{paddingTop: '50px', height: '50%', overflowY: 'scroll'}}>
        <Col>
            <div>
                <h2>Friday</h2>
                <p>TBD - Check-in & arrival for guests (sometime afternoonish) </p>
                <p>TBD - Dinner (on your own or with us) </p>
                <p>After dinner - TBD (unforunately, there's a burn ban in Asheville currently so no campfire)</p>
            </div>
            <div>
                <h2>Saturday</h2>
                <p>8:00am - Breakfast for those spending the night with us</p>
                <p> * Bridesmaids will need to join Drew to begin getting ready * </p>
                <p>2:30pm - Arrival to ceremony </p>
                <p>3:00pm - Ceremony begins </p>
                <p>4:00pm - Light refreshments </p>
                <p>5:00pm - Dinner </p>
                <p>6:00pm & until - Reception </p>
            </div>
            <div>
                <h2>Sunday</h2>
                <p>TBD - Check-Out</p>
            </div>
        </Col>
    </Row>
    </Container>
};
export default Schedule;