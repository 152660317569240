import { Col, Container, Row } from "react-bootstrap"

const Details: React.FC = () => {
    return <Container fluid style={{height: '100vh', textAlign: 'center', padding: '20px', width: '100vw', backgroundColor: 'rgb(40, 44, 52)',}}>   
    <Row>
        <Col>
            <h1>Details</h1>
        </Col>
    </Row>
    <Row style={{paddingTop: '50px', paddingBottom: '50px', height: '50%', overflowY: 'scroll'}}>
        <Col>
            <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <p>
                   We wanted to invite you to join us as we bring our closest friends and family together to celebrate our commitment to eachother in a non-traditional setting. 
                </p>
            </div>
            <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <h3>
                    Date
                </h3>
                <p>
                    Saturday
                </p>
                <p>
                    December 2nd, 2023
                </p>
            </div>
            <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <h3>
                    Venue
                </h3>
                <p>
                    <a href="https://bentcreeknc.com/">Bent Creek Lodge</a>
                </p>
            </div>
            <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <h3>
                    Address
                </h3>
                <p>
                    10 Parkway Crescent, Arden, NC 28704
                </p>
            </div>
            <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <h3>
                    Check-In/Check-Out
                </h3>
                <p>
                   ~ TBD ~
                </p>
            </div>
            <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <h3>
                    Arrival
                </h3>
                <p>
                    Please try to arrive, at the latest, at 2:30pm. 
                </p>
                <p>
                    Our wedding ceremony will be held at 3:00pm with refreshments and dinner to follow. 
                </p>
                <p>
                    Guests have the option to arrive as early as Friday afternoon when the lodge accepts check-ins and spend the night with us. 
                </p>
            </div>
            <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <h3>
                    Things to do
                </h3>
                <p>
                    Working on gathering resources, for now <a href="https://bentcreeknc.com/asheville/things-to-do/"> Bent Creek's Website</a> is an excellent resource.
                </p>
                <p>
                    The lodge is located near access to hiking trails that connect to the Blue Ridge Parkway. Hiking is very popular along with water activities as the lodge also sits across from the French Broad River.
                </p>
            </div>
        </Col>
    </Row>
    </Container>
};
export default Details;