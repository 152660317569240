import React, { useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, } from 'react-router-dom';
import Home from './Home';
import Header from './Header';
import Footer from './Footer';
import Schedule from './Schedule';
import FAQ from './FAQ';
import Details from './Details';
import OurStory from './OurStory';
import LoginPage from './Login';
import { AuthContext } from './AuthContext';
import Index from '.';
import PrivateRoute from './PrivateRoute';
import NewFooter from './NewFooter';
import NewHeader from './NewHeader';

function MainApp() {
  const { user, loading } = useContext(AuthContext);
  const navigate = useNavigate();

//   useEffect(() => {
//     if (user) {
//       navigate("/");
//     }
//   }, [user, navigate]);

  if (loading) return <div>Loading...</div>;

  return (
    <>
        {/* <Header /> */}
        {/* <Footer /> */}
        <NewHeader />
            <div id="main" style={{ position: 'relative', width: '100%', overflowX: 'hidden' }}>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="index" element={<Index />} />
            <Route 
                path="/"
                element={<PrivateRoute />}
            >
                <Route path="details" element={<Details />} />
                <Route path="schedule" element={<Schedule />} />
                <Route path="FAQ" element={<FAQ />} />
            </Route>
        </Routes>
      </div>
      <NewFooter />
    </>
  );
}

export default MainApp;
